@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    font-family: Urbanist, sans-serif;

}

.sair {
    /* width: 80%; */
    height: 40px !important;
    background-color: #2196F3 !important;
    box-shadow: none !important;
    border-radius: 8px !important;
}


.header {
    width: 100vw;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;

}

.logo {
    width: 50px !important;
}

nav a {
    cursor: pointer;
    font-weight: 300;
    letter-spacing: 0.1cap;
    text-decoration: none;
    color: rgb(39, 39, 39);
    font-size: 13px;
}

.link-app-bar {
    margin: 0px 0px 0px 15px !important;
}

.nav_bar {
    background-color: rgb(255, 255, 255) !important;
    box-shadow: none !important;
}

.css-10hburv-MuiTypography-root {
    color:black;
}

.title{
    padding-left: 30px;
    color: #363636cc
}

@media (max-width: 800px) {
    .menu-icon svg {
        color: black; /* Set the color of the MenuIcon to black */
    }
    .title{
        padding-left: 0px;
        
    }
   
}

