.footer {
    width: 100%;
    height: 200px;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec-one {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo {
    width: 40px;
}

.name-contain p {
    font-size: 15px;
    padding: 0;
    margin: 0;
}