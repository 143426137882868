body {
    color: black;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;

}

.pagname {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #262525;
    margin-top: 130px;
}

.pagname h1 {
    color: #262525;
}

.listview {
    display: flex;
    gap: 40px;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;


}