@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: Urbanist, sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-boxs;

}

.texto-historia {
    color: #686868;
}

.texto-historia-principal {
    color: #686868;
    /* border: 2px solid #686868;
    border-radius: 30px;
    padding: 10px; */
}

.ns-section {
    /* background-image: url(../../assets/bg-ns.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    /* width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;*/
    padding: 40px 0px;
}

.ns-max-w {
    max-width: 1240px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
}

.ns-title h1 {
    font-size: 30px;
    font-weight: 400;
}

.ns-contain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    margin-bottom: 20px;

}

.image img {
    width: 110%;
}

.text {
    width: 55%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}



.text h1 {
    font-size: 60px;
    font-weight: 500;
}

.text p {
    font-family: 14px;
    font-weight: 300;
}

.ns-line {
    display: flex;
    justify-content: center;
}

.ns-line img {
    width: 60%;
}

.ns-line-color {
    color: blue;
}

@media screen and (max-width: 1000px) {
    .image {
        display: flex;
        justify-content: center;
    }

    .image img {
        width: 80%;
    }

    .ns-contain {
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        margin-top: 20px;
    }

    .text {
        width: 90%;

    }

    .ns-line img {
        width: 80%;
    }
}

@media screen and (max-width: 650px) {
    .ns-section {
        background-position: 30%;
    }

    .ns-contain {
        margin-bottom: 10px;
    }
}